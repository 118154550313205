.rss__contentWrapper {
    height: 100vh;
    padding: 5vh;

    scroll-snap-align: start;

    display: flex;
    flex-flow: column nowrap;
}

.rss__imageWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;

    height: 100%;

    gap: 1em; 
}

.rss__imageCol {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;

    gap: 1em;
}

.rss__image {
    height: 100%;
    width: 100%;
    background: var(--white) no-repeat center center;
    background-size: cover;
}

.rss__image:hover {
    border-radius: 0.5em;
}

.rss__image div {
    height: 100%;
    width: 100%;
    background-color: var(--black);
    opacity: 0;

    transition: opacity 0.8s;
    color: var(--white);

    padding: 0.3em 0.3em 0.5em 0.5em;

    line-height: 1.4em;
}

.rss__image div p {
    font-size: 1.2rem;
    line-height: 1.1em;
}

.rss__image:hover div {
    opacity: 0.75;
    border-radius: 0.5em;
}

.rss__image.half {
    flex-shrink: 2;
}

.rss__image.twoThird {
    flex-shrink: 1.5;
}

.rss__image.threeQua {
    flex-shrink: calc(4/3);
}

.content1 {
    background-image: url('../../resources/content1.jpg');
}

.content2 {
    background-image: url('../../resources/content2.jpg');
    background-position: 50% 10%;
}

.content3 {
    background-image: url('../../resources/content3.jpg');
    background-position: 25% 50%;
}

.content4 {
    background-image: url('../../resources/content4.jpg');
    background-position: 85% 50%;
}

.content5 {
    background-image: url('../../resources/content5.jpg');
    background-position: 50% 80%;
}

.content6 {
    background-image: url('../../resources/content6.jpg');
    background-position: 50% 40%;
}

.content7 {
    background-image: url('../../resources/content7.jpg');
    background-position: 50% 40%;
}

.content8 {
    background-image: url('../../resources/content8.jpg');
    background-position: 50% 30%;
}

.content9 {
    background-image: url('../../resources/content9.jpg');
    background-position: 50% 80%;
}

.content10 {
    background-image: url('../../resources/content10.jpg');
    background-position: 40% 80%;
}

@media only screen and (min-width: 768px) {

    .rss__imageWrapper {
        flex-flow: row nowrap;
    }

    .rss__imageCol {
        flex-flow: column nowrap;
    }

}