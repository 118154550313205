.rss__titleCard {
    width: 100%;

    height: 100vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    color: var(--black);

    filter: invert(0.45);
    mix-blend-mode: multiply;
    isolation: isolate;
}

.rss__pivot {
    height: 100vh;
    width: 100%;

    background-size: cover;

    background-position: 65% center;

    background-image: url('../../resources/pivot-mobile.jpg');

    scroll-snap-align: start;
}

.rss__coverUp {
    transition: opacity 1.5s;

    position: absolute;
    width: 100%;
}

.image {
   opacity: 1;
}

.color {
    opacity: 0;
}

@media only screen and (min-width: 768px) {
    .rss__titleCard {
        height: 80vh;
        margin-bottom: 20vh;
    }

    .rss__pivot {
        background: var(--white) url('../../resources/pivot.jpg') no-repeat fixed center center;  
    }
}