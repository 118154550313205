* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

html, body {
    height: 100%;
    background: var(--tan);
    font-family: var(--font-family);
    color: var(--black);

    scroll-snap-type: y mandatory;
}

.black {
    color: var(--black);
}

.white {
    color: var(--white);
}

a {
    color: unset;
    text-decoration: none;
}

h1 {
    font-size: 15rem;
}

h2 {
    font-size: 10rem;
    margin-top: 5rem;
}

h3 {
    font-size: 7rem;
}

h4 {
    font-size: 4.5rem;
}

p {
    font-size: 2rem;
}

div {
    font-size: 1.5rem;
}

@media only screen and (min-width: 768px) {
    
    h1 {
        font-size: 30rem;
    }
    
    h2 {
        font-size: 19rem;
        margin-top: 9rem;
    }
    
    h3 {
        font-size: 10rem;
        line-height: 8rem;
    }
    
    h4 {
        font-size: 4rem;
        line-height: 6rem;
    }

}