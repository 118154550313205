.rss__footer {
    height: 4rem;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.rss__footer_links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;
    padding-top: 0;
    padding-left: 6rem;
    padding-right: 6rem;
}

.rss__footer_links * {
    cursor: pointer;
}

.rss__footer_copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.rss__footer_links * , .rss__footer_copyright * {
    font-size: 1rem;
}