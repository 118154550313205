@font-face {
    font-family: bauer;
    src: url("./resources/bauer.otf") format("opentype");
}

html {
    font-size: calc(0.8vw + 0.8vh);
}

@media screen and (max-width: 550px) {
    html {
        font-size: 2.5vw;
    }
}

:root {
   --font-family:  'bauer';

    --white: #FDFDFD;
    --black: #1F1F1F;
    --tan: #EBE5D8;

}